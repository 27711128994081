<template>
  <div class="minister_wrapper">
    <div class="org_filter_wrapper" style="margin: -10px 0px 0;padding: 0;background: transparent;">
      <b-row>
        <b-col sm="3">
          <div class="form-group">
            <label for="org_id" class="form-label mb-0">{{ $t('globalTrans.organization') }}</label>
            <b-form-select
              plain
              v-model="search.org_id"
              :options="orgList"
              id="org_id"
              class="bg-white"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.all')}}</b-form-select-option>
              </template>
            </b-form-select>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="form-group">
            <label for="from_date" class="form-label mb-0">{{ $t('globalTrans.from_date') }}</label>
            <flat-pickr class="form-control bg-white w-100"
              :config="{ static: true }"
              v-model="search.from_date"
              :placeholder="$t('globalTrans.select_date')"
            ></flat-pickr>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="form-group">
            <label for="to_date" class="form-label mb-0">{{ $t('globalTrans.to_date') }}</label>
            <flat-pickr class="form-control bg-white"
              :config="{ static: true }"
              v-model="search.to_date"
              :placeholder="$t('globalTrans.select_date')"
            ></flat-pickr>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex align-items-center justify-content-between h-100">
            <button type="submit" class="btn btn-primary mt-2" style="padding: 9px 0.75rem;box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;border: none;" @click="searchData"><i class="fas fa-search"></i> {{ $t('globalTrans.search') }}</button>
            <button type="submit" class="btn btn-info btn-print mt-2" style="padding: 9px 0.75rem;box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;border: none;" @click="pdfDownload"><i class="fas fa-print"></i> Print</button>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-overlay :show="loading">
      <img id="logoId" src="../../../../../assets/images/logo.png" style="width:40px; height:40px; display:none;">
      <table class="table table-hover movement-table">
        <thead class="text-center">
          <tr>
            <th scope="col">{{ $t('globalTrans.sl_no') }}</th>
            <th scope="col">{{ $t('globalTrans.date') }}</th>
            <th scope="col">{{ $t('globalTrans.name') }}</th>
            <th scope="col">{{ $t('globalTrans.designation') }}</th>
            <th scope="col">{{ $t('globalTrans.location') }}</th>
            <th scope="col">{{ $t('authentication.tour_details') }}</th>
            <th scope="col">{{ $t('globalTrans.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(movement, index1) in movements" >
            <tr :rowspan="movement.details.length" :key="index1" class="org-wrapper">
              <td colspan="7">
                <p class="org-title">{{ currentLocale === 'en' ? movement.org_name_en : movement.org_name_bn }}</p>
              </td>
            </tr>
            <tr v-for="(item, index2) in movement.details" :key="index2">
              <td>{{ $n(item.serial) }}</td>
              <td>{{ item.date | dateFormat }}</td>
              <td>{{ currentLocale === 'en' ? item.user.name : item.user.name_bn }}</td>
              <td>{{ currentLocale === 'en' ? item.designation_en : item.designation_bn }}</td>
              <td>{{ currentLocale === 'en' ? item.location_en : item.location_bn }}</td>
              <td>{{ currentLocale === 'en' ? item.tour_details_en.slice(0, 30) + '...' : item.tour_details_bn.slice(0, 30) + '...' }}</td>
              <td>
                <a v-if="item.attachment" :title="$t('globalTrans.attachment')" :href="baseUrl + 'download-attachment?file=' + item.attachment">
                    <i class="ri-download-cloud-line"></i>
                </a>
                <a href="javascript:" class="btn_table_action table_action_view" :title="$t('globalTrans.view')" v-b-modal.modal-5 size="sm" @click="details(item)"><i class="fas fa-eye"></i></a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </b-overlay>
    <b-modal id="modal-5" size="lg" :title="$t('user.movement_summary')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <MovementDetails :item="item" />
    </b-modal>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { authServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import { movementSummary } from '../../api/routes'
import ExportPdf from './movement_pdf'
import MovementDetails from './MovementDetails'

export default {
    name: 'Movement',
    components: {
      MovementDetails
    },
    data () {
      return {
        loading: false,
        item: '',
        organizationList: [],
        designationList: [],
        movements: [],
        baseUrl: authServiceBaseUrl,
        search: {
          org_id: 0,
          from_date: '',
          to_date: ''
        }
      }
    },
    computed: {
      currentLocale: function () {
        return this.$i18n.locale
      },
      orgList: function () {
        return this.organizationList.map(item => {
          const tmp = this.currentLocale === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
        })
      }
    },
    created () {
      this.getCommonDropdownData()
      this.loadData()
    },
    watch: {
      currentLocale: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.translateDropdown()
        }
      }
    },
    mounted () {
      core.index()
    },
    methods: {
      searchData () {
        this.loadData()
      },
      async loadData () {
        this.loading = true
        if (this.orgList.length > 0) {
          const response = await RestApi.getData(authServiceBaseUrl, movementSummary, this.search)
          this.loading = false
          if (response.success) {
            this.movements = this.getRelationalData(response.data)
          } else {
            this.movements = []
          }
        }
      },
      getRelationalData (data) {
        return data.map(item => {
          const org = this.orgList.find(el => el.value === item.org_id)
          const orgDetails = Object.assign(item, {
            org_name_en: typeof org !== 'undefined' ? org.text_en : '',
            org_name_bn: typeof org !== 'undefined' ? org.text_bn : ''
          })
          let sl = 1
          const finalDetails = item.details.map(details => {
            const desg = this.designationList.find(el => el.value === details.designation_id)
            return Object.assign(details, {
              serial: sl++,
              designation_en: typeof desg !== 'undefined' ? desg.text_en : '',
              designation_bn: typeof desg !== 'undefined' ? desg.text_bn : ''
            })
          })
          return Object.assign(item, orgDetails, finalDetails)
        })
      },
      async getCommonDropdownData () {
        await RestApi.getData(commonServiceBaseUrl, 'ministry-common-dropdown', {}).then(response => {
          if (response.success) {
            this.organizationList = this.getCommonFormat(response.data.orgList)
            this.designationList = this.getCommonFormat(response.data.designationList)
            this.loadData()
          }
        })
      },
      getCommonFormat (data) {
        return data.map(item => {
          const tmp = this.currentLocale === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
        })
      },
      translateDropdown () {
        this.orgList.map(item => {
          const tmp = this.currentLocale === 'en' ? { text: item.text_en } : { text: item.text_bn }
          return Object.assign({}, item, tmp)
        })
      },
      async pdfDownload () {
        this.loading = true
        const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
        const reportTitle = this.$t('ministryDashboard.movement_summary_report')
        await ExportPdf.movementSummaryPdfDetails(reportTitle, base64Logo, this)
        this.loading = false
      },
      getBase64Logo (logo) {
        var canvas = document.createElement('canvas')
        canvas.width = logo.width
        canvas.height = logo.height
        var ctx = canvas.getContext('2d')
        ctx.drawImage(logo, 0, 0)
        var dataURL = canvas.toDataURL('image/png')
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
      },
      details (editItem) {
        this.item = editItem
      }
    }
}
</script>
