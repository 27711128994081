<template>
    <div>
      <b-overlay :show="loading">
        <b-row>
          <b-col sm="3">
            <div class="license_card_item_wrapper warehouse_single_card production_wrapper">
                <p class="main_title">{{ $t('ministryDashboard.khudro_rin_dam') }}</p>
              <div class="item_1">
                <img src="../../../../../assets/images/ministry-dashboard/warehouse-DAM.svg" class="item_icon img-fluid">
                <h2 class="count production_count">{{ $n(data.damWarehouseKhudroRin.total_warehouse) }}</h2>
              </div>
              <div class="item_2 total">
                <p class="title">{{ $t('ministryDashboard.capacity') }}: <span>{{ formatCapacity(data.damWarehouseKhudroRin.total_capacity) }} {{ $t('ministryDashboard.quintal') }}</span></p>
              </div>
            </div>
          </b-col>
          <b-col sm="3">
            <div class="license_card_item_wrapper warehouse_single_card allocation_wrapper production_wrapper">
              <p class="main_title">{{ $t('ministryDashboard.private') }}</p>
              <div class="item_1">
                <img src="../../../../../assets/images/ministry-dashboard/warehouse-Private.svg" class="item_icon img-fluid">
                <h2 class="count production_count">{{ $n(data.private.total_warehouse) }}</h2>
              </div>
              <div class="item_2 total">
                <p class="title">{{ $t('ministryDashboard.capacity') }}: <span>{{ formatCapacity(data.private.total_capacity) }} {{ $t('ministryDashboard.quintal') }}</span></p>
              </div>
            </div>
          </b-col>
          <b-col sm="3">
            <div class="card_item_wrapper sale_wrapper warehouse_card_item">
                <div class="number_wrapper">
                <p class="number">
                    <img src="../../../../../assets/images/ministry-dashboard/farmer.svg" class="item_icon img-fluid">
                </p>
                </div>
                <div class="card_item">
                <p class="title">{{ $t('ministryDashboard.total_farmer') }}</p>
                <h2 class="count sale_count">{{ $n(data.totalFarmer) }}</h2>
                </div>
            </div>
          </b-col>
          <b-col sm="3">
            <div class="card_item_wrapper stock_wrapper warehouse_card_item">
                <div class="number_wrapper">
                <p class="number">
                    <img src="../../../../../assets/images/ministry-dashboard/stock.svg" class="item_icon img-fluid">
                </p>
                </div>
                <div class="card_item">
                <p class="title">{{ $t('ministryDashboard.total_stock') }}</p>
                <h2 class="count stock_count">{{ formatCapacity(data.warehouseStatus.current_stock) }}<span>{{ $t('ministryDashboard.quintal') }}</span></h2>
                </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <div class="grapChart_wrapper">
              <h5 class="card_title">{{ $t('ministryDashboard.crops_status') }}</h5>
              <apexchart type="bar" height="350" :options="cropStatusChartOptions" :series="cropStatusSeries"></apexchart>
            </div>
          </b-col>
          <b-col sm="6">
            <div class="grapChart_wrapper">
              <h5 class="card_title">{{ $t('ministryDashboard.warehouse_status') }}</h5>
              <apexchart type="pie" width="380" :options="warehouseStatusChartOptions" :series="warehouseStatusSeries"></apexchart>
            </div>
          </b-col>
        </b-row>
        <div class="text-right">
          <router-link :to="{ name: 'authSignUp.dashboard', params: { dashboardUrl: '/warehouse/report/ware-information-report' }}" class="details_btn inc_btn">{{ $t('ministryDashboard.detail_btn') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
        </div>
      </b-overlay>
    </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { ministryDashboardWarehouse } from '../../api/routes'

export default {
  name: 'WarehouseDashboard',
  data () {
    return {
      loading: false,
      data: {
        damWarehouseKhudroRin: {
          total_warehouse: 0,
          total_capacity: 0
        },
        private: {
          total_warehouse: 0,
          total_capacity: 0
        },
        totalFarmer: 0,
        warehouseStatus: {
          current_stock: 0,
          available_stock: 0
        },
        cropStockInfo: []
      },
      cropStatusSeries: [{
        data: []
      }],
      cropStatusCategories: [],
      cropStatusCategoriesBn: [],
      warehouseStatusSeries: [44, 55]
    }
  },
  computed: {
    currentLocale: function () {
      return this.$i18n.locale
    },
    cropStatusChartOptions () {
      return {
        chart: {
          type: 'bar',
          height: 320
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            columnWidth: '50%'
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: (this.currentLocale === 'en' ? this.cropStatusCategories : this.cropStatusCategoriesBn),
          title: {
            text: 'Product -> Cartegory'
          }
        },
        yaxis: {
          title: {
            text: 'Count'
          }
        }
      }
    },
    warehouseStatusChartOptions () {
      return {
        chart: {
          width: 380,
          type: 'pie'
        },
        labels: [this.$t('ministryDashboard.available'), this.$t('ministryDashboard.stock_')],
        colors: ['#118DFF', '#12239E'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    core.index()
  },
  methods: {
    async loadData () {
      this.loading = true
      await RestApi.getData(warehouseServiceBaseUrl, ministryDashboardWarehouse)
        .then(response => {
          if (response.success) {
            this.data = response.data
            const cropStatusSeariesData = []
            const cropStatusCategories = []
            const cropStatusCategoriesBn = []
            this.data.cropStockInfo.forEach(crop => {
              cropStatusSeariesData.push(crop.total_quantity)
              cropStatusCategories.push(crop.crop_name)
              cropStatusCategoriesBn.push(crop.crop_name_bn)
            })
            this.cropStatusSeries = [{
              data: cropStatusSeariesData
            }]
            this.cropStatusCategories = cropStatusCategories
            this.cropStatusCategoriesBn = cropStatusCategoriesBn
            this.warehouseStatusSeries = [this.data.warehouseStatus.available_stock, this.data.warehouseStatus.current_stock]
            this.$set(this.warehouseStatusSeries, 0, this.data.warehouseStatus.available_stock)
            this.$set(this.warehouseStatusSeries, 1, this.data.warehouseStatus.current_stock)
            this.loading = false
          } else {
            this.data = {
              damWarehouseKhudroRin: {
                total_warehouse: 0,
                total_capacity: 0
              },
              private: {
                total_warehouse: 0,
                total_capacity: 0
              },
              totalFarmer: 0,
              warehouseStatus: {
                current_stock: 0,
                available_stock: 0
              },
              cropStockInfo: []
            }
            this.loading = false
          }
        })
    },
    formatCapacity (amount) {
      const amountInLakh = parseFloat(amount) / 100000
      const finalAmount = amountInLakh >= 1 ? this.$n(amountInLakh.toFixed(2)) + ' ' + this.$t('ministryDashboard.lakh') : this.$n(amount)
      return finalAmount
    }
  }
}
</script>
