<template>
  <div>
    <b-overlay :show="loadChart">
      <b-row class="">
        <section id="marquee" class="mt-2">
          <MarqueeText :item="item" :retailScroll="retailScroll" :wholeScroll="wholeScroll" :retailPriceDate="retailPriceDate"  :wholeSalePriceDate="wholeSalePriceDate"/>
        </section>
        <section id="card" style="margin-top: 10px">
          <div class="custom-container">
            <b-row>
              <slot v-if="commodityLoad">
                <b-col>
                  <div class="text-center">
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden"></span>
                    </div>
                  </div>
                </b-col>
              </slot>
              <slot v-else>
                <b-col
                  cols="12"
                  class="custom-col mb-3"
                  v-for="(item, index) in visibleItems"
                  :key="index"
                  @click="itemAssign(item)"
                  v-b-modal.modal-4
                  style="cursor:pointer !important"
                >
                  <CardComp :item="item" :backgroundImage="backgroundImageCheck(item)"></CardComp>
                </b-col>
              </slot>
              <b-col cols="12" class="mb-2" v-if="!commodityLoad">
                <div class="text-right">
                  <button @click="toggleShowMore" class="details_btn inc_btn" style="margin-top:0px;">
                    {{ itemsToShow === 8 ? $t('globalTrans.show_more') : $t('globalTrans.hide') }} <i class="fas fa-long-arrow-alt-right"></i>
                  </button>
                </div>
              </b-col>
            </b-row>
          </div>
        </section>
      </b-row>
    </b-overlay>
    <b-modal id="modal-4" size="lg" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <div v-if="commodityId">
        <ComparativeCommodityReportTop :retailPriceDate="retailPriceDate" :commodityData="commodityData" :commodityId="commodityId"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import MarqueeText from './dam/MarqueeText'
import CardComp from './dam/CardComp'
import ComparativeCommodityReportTop from './dam/ComparativeCommodityReportTop'

export default {
    components: {
      MarqueeText,
      ComparativeCommodityReportTop,
      CardComp
    },
    data () {
      return {
        retailScroll: [],
        wholeScroll: [],
        selectedCommodity: [],
        retailPriceDate: '',
        wholeSalePriceDate: '',
        retailPriceDateLast: '',
        wholeSalePriceDateLast: '',
        commodityLoad: false,
        commodityId: 0,
        commodityData: [],
        backgroundImage1: require('@/assets/images/bazar/card-bg-1.svg'),
        backgroundImage2: require('@/assets/images/bazar/card-bg-2.svg'),
        backgroundImage3: require('@/assets/images/bazar/card-bg-3.svg'),
        backgroundImage4: require('@/assets/images/bazar/card-bg-4.svg'),
        backgroundImage5: require('@/assets/images/bazar/card-bg-5.svg'),
        itemsToShow: 8,
        loadChart: false
      }
    },
    computed: {
      visibleItems () {
        return this.selectedCommodity.slice(0, this.itemsToShow)
      },
      showMoreButton () {
        return this.itemsToShow < this.selectedCommodity.length
      },
      local: function () {
        return this.$i18n.locale
      }
    },
    watch: {
      local: function (newVal, oldVal) {
        if (newVal !== oldVal) {
            this.getLanguateWiseData()
        }
      }
    },
    created () {
      this.loadData()
    },
    methods: {
      itemAssign (item) {
        this.commodityId = 0
        this.commodityId = item.commodity_id
        this.commodityData = item
      },
      toggleShowMore () {
        this.itemsToShow = this.itemsToShow === 8 ? this.selectedCommodity.length : 8
      },
      getRelationalData (data) {
        const selectedData = data.filter(dataL => dataL.highlighted_commodity === 1)
        let count = 1
        const list = selectedData.map((item, index) => {
          // const backImg = '@/assets/imgs/card-bg-2.svg'
          // let backImage = require(backImg)
          const backImage = ''
          let backgroundColor = ''
          if (count === 1) {
            backgroundColor = '#CDDBFF'
          } else if (count === 2) {
            backgroundColor = '#F6D0F6'
          } else if (count === 3) {
            backgroundColor = '#BBFFDD'
          } else if (count === 4) {
            backgroundColor = '#CDDBFF'
          }
          const backData = {
            backImage: backImage,
            backgroundColor: backgroundColor
          }
          const selectFieldData = {
            selectField: count++
          }
          if (count > 4) {
            // if (count === 1) {
            //   backImage = 'card-bg-' + count
            // }
            count = 1
          }
          return Object.assign({}, item, selectFieldData, backData)
        })
        return list
      },
      backgroundImageCheck (item) {
        let backgroundImage = ''
        if (parseInt(item.selectField) === 1) {
          backgroundImage = this.backgroundImage1
        }
        if (parseInt(item.selectField) === 2) {
          backgroundImage = this.backgroundImage2
        }
        if (parseInt(item.selectField) === 3) {
          backgroundImage = this.backgroundImage3
        }
        if (parseInt(item.selectField) === 4) {
          backgroundImage = this.backgroundImage4
        }
        return backgroundImage
      },
      loadData () {
        this.commodityLoad = true
        RestApi.getData(
          agriMarketingServiceBaseUrl,
          'bazar-dor/scroll-price',
          null
        ).then((response) => {
          if (response.success) {
            this.commodityLoad = false
            this.retailPriceDate = response.retailPriceDate
            this.wholeSalePriceDate = response.wholeSalePriceDate
            this.selectedCommodity = this.getRelationalData(response.scrollRetail)
            this.retailScroll = response.scrollRetail
            this.wholeScroll = response.scrollWholeSale
          } else {
            this.commodityLoad = false
          }
        })
      }
    }
}
</script>
<style scoped>
@media (min-width: 576px) {
  .custom-col {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
}
</style>
