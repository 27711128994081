<template>
  <div>
    <b-overlay :show="loadSearch">
      <b-row>
        <div class="col-12">
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
              <b-row>
                <b-col lg="8" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="3"
                      :label="$t('elearning_config.organization')"
                      label-for="organization"
                  >
                      <v-select name="organization"
                          v-model="search.org_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= orgNameList
                          :placeholder="$t('globalTrans.all')"
                      />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </div>
        <div class="col-12">
          <b-overlay :show="loading">
            <b-row>
              <b-col sm="3">
                <div class="card_item_wrapper inc_amount_wrapper" style="height: 127px;">
                  <div class="number_wrapper">
                    <p class="number">
                      <img src="../../../../../assets/images/ministry-dashboard/training.svg" class="item_icon">
                    </p>
                  </div>
                  <div class="card_item">
                    <p class="title">{{ $t('elearning_config.total_training') }}</p>
                    <h2 class="count count_amount">{{$n(totalTraining)}}</h2>
                  </div>
                </div>
              </b-col>
              <b-col sm="3">
                <div class="card_item_wrapper inc_seed_wrapper" style="height: 127px;">
                  <div class="number_wrapper">
                    <p class="number">
                      <img src="../../../../../assets/images/ministry-dashboard/trainee.svg" class="item_icon">
                    </p>
                  </div>
                  <div class="card_item">
                    <p class="title">{{ $t('globalTrans.total_trainee') }}</p>
                    <h2 class="count count_seed">{{$n(totalTrainee)}}</h2>
                  </div>
                </div>
              </b-col>
              <b-col sm="3">
                <div class="card_item_wrapper inc_fertilizer_wrapper" style="height: 127px;">
                  <div class="number_wrapper">
                    <p class="number">
                        <img src="../../../../../assets/images/ministry-dashboard/official-trainee.svg" class="item_icon">
                    </p>
                  </div>
                  <div class="card_item">
                    <p class="title">{{ $t('elearning_config.male_female_participant') }}</p>
                    <h2 class="count count_fertilizer">{{$n(maleTrainee)}}/{{$n(femaleTrainee)}}</h2>
                  </div>
                </div>
              </b-col>
              <b-col sm="3">
                <div class="card_item_wrapper inc_farmer" style="height: 127px;">
                  <div class="number_wrapper">
                    <p class="number">
                        <img src="../../../../../assets/images/ministry-dashboard/official-trainee.svg" class="item_icon">
                    </p>
                  </div>
                  <div class="card_item">
                    <p class="title">{{ $t('globalTrans.total_trainer') }}</p>
                    <h2 class="count count_farmer">{{$n(totalTrainer)}}</h2>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="justify-content-center">
              <b-col sm="7">
                <div class="grapChart_wrapper">
                  <h5 class="card_title">{{ $t('ministryDashboard.year_wise_trainee_info') }}</h5>
                  <apexchart width="500" type="bar" v-if="searchThis" :options="chartOptions" :series="seriesDatas"></apexchart>
                </div>
              </b-col>
              <b-col sm="5">
                <div class="grapChart_wrapper">
                  <h5 class="card_title">{{ $t('ministryDashboard.male_female_trainee_status') }}</h5>
                  <apexchart class="chart-1 text-center px-5" height="350" v-if="searchThis" type="pie" :options="spaceAvailableDashboardLabels" :series="series()"></apexchart>
                </div>
              </b-col>
            </b-row>
            <div class="text-right">
              <router-link :to="{ name: 'authSignUp.dashboard', params: {dashboardUrl: '/training-e-learning-service/report/training-summary-report' }}" class="details_btn inc_btn">{{ $t('ministryDashboard.detail_btn') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
            </div>
          </b-overlay>
        </div>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl, commonServiceBaseUrl } from '@/config/api_config'
import moment from 'moment'

export default {
  name: 'UiDataTable',
  data () {
    return {
      loadSearch: true,
      searchThis: false,
      loading: false,
      totalTraining: 0,
      totalTrainee: 0,
      maleTrainee: 0,
      femaleTrainee: 0,
      totalTrainer: 0,
      officialTrainee: 0,
      otherTrainee: 0,
      orgList: [],
      seriesData: [],
      seriesDataGrap: [],
      search: {
        fiscal_year_id: 0,
        org_id: null
      },
      datas: [],
      firstLabel: ['Male', 'Female'],
      firstLabelBn: ['পুরুষ', 'মহিলা'],
      chartLabel: [],
      chartLabelBn: [],
      seriesDatas: [{
        name: 'series-1',
        data: []
      }],
      fiscalYearList: []
    }
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          height: 320,
          id: 'basic-bar'
        },
        xaxis: {
          // categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
          categories: this.$i18n.locale === 'bn' ? this.chartLabelBn : this.chartLabel
        }
      }
    },
    spaceAvailableDashboardLabels () {
      return {
        chart: {
          height: 320,
          type: 'pie'
        },
        labels: this.$i18n.locale === 'bn' ? this.firstLabelBn : this.firstLabel,
        colors: ['#02ADEE', '#ED008C'],
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    },
    yearList: function () {
      const toBn = n => n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      var max = new Date().getFullYear()
      var min = max - 5
      var years = []
      for (var i = max; i >= min; i--) {
        const yearData = {}
        yearData.value = i
        yearData.text = this.$i18n.locale === 'bn' ? this.$n(i, { useGrouping: false }) : i
        yearData.text_en = i
        yearData.text_bn = toBn(String(i))
        years.push(yearData)
      }
      return years
    },
    orgNameList: function () {
    return this.orgList.map(obj => {
      if (this.$i18n.locale === 'bn') {
        return { value: obj.value, text: obj.text_bn }
        } else {
        return { value: obj.value, text: obj.text_en }
        }
    })
  }
    // orgList: function () {
    //     return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    // }
  },
  async created () {
    this.fiscalYear()
    this.getOrgLIst()
  },
  watch: {
    'search.org_id': function (newValue) {
      this.totalTraining = 0
      this.totalTrainee = 0
      this.maleTrainee = 0
      this.femaleTrainee = 0
      this.totalTrainer = 0
      this.chartLabel = []
      this.chartLabelBn = []
      this.firstLabel = ['Male', 'Female']
      this.firstLabelBn = ['পুরুষ', 'মহিলা']
      this.seriesDataGrap = []
      this.loadData()
    }
  },
  methods: {
    async getOrgLIst () {
      await RestApi.getData(commonServiceBaseUrl, 'ministry-common-dropdown', {}).then(response => {
        if (response.success) {
          this.orgList = response.data.orgList
        }
      })
    },
    series () {
      return this.seriesDataGrap
    },
    yearCheck (value) {
      if (this.$i18n.locale === 'bn') {
         return moment(value).format('Y')
      } else {
        return moment(value).format('Y')
      }
    },
    async fiscalYear () {
      await RestApi.getData(commonServiceBaseUrl, 'ministry-common-dropdown', {}).then(response => {
        if (response.success) {
          this.fiscalYearList = response.data.fiscalYearList
          this.setCurrentFiscalYear(this.fiscalYearList)
        }
      })
    },
    setCurrentFiscalYear (fiscalYearList) {
      if (fiscalYearList.length === 0) {
        return
      }
      const currentDate = new Date()
      const [month, year] = [currentDate.getMonth(), currentDate.getFullYear()]
      /** Define the year position  */
      const yearPosition = month < 7 ? 5 : 0
      const yearStr = `${year}`
      let currentFiscalYearId = 0
      fiscalYearList.forEach(element => {
        if (element.text_en.indexOf(yearStr) === yearPosition) {
          currentFiscalYearId = element.value
        }
      })
      this.search.fiscal_year_id = currentFiscalYearId
      this.loadData()
    },
    async loadData () {
      this.loading = true
      await RestApi.getData(trainingElearningServiceBaseUrl, 'ministry-dashboard', this.search).then(response => {
        if (response.success) {
          if (response.totalTraining) {
            this.totalTraining = response.totalTraining
          }
          if (response.totalTrainee) {
            this.totalTrainee = response.totalTrainee
          }
          if (response.totalTrainer) {
            this.totalTrainer = response.totalTrainer
          }
          if (response.maleTrainee) {
            this.maleTrainee = response.maleTrainee
            this.seriesDataGrap.push(response.maleTrainee)
          }
          if (response.femaleTrainee) {
            this.femaleTrainee = response.femaleTrainee
            this.seriesDataGrap.push(response.femaleTrainee)
          }
          const yearVal = []
          this.yearList.map(item => {
            const ministryDataMonthList = response.yearlyTrainee.find(yearWiseTraining => parseInt(yearWiseTraining.year) === parseInt(item.value))
            if (typeof ministryDataMonthList !== 'undefined') {
              yearVal.push(ministryDataMonthList.total.toFixed(2))
            } else {
              yearVal.push(0)
            }
            this.chartLabelBn.push(item.text_bn)
            this.chartLabel.push(item.text_en)
          })
          this.seriesDatas = [{
            name: 'Year wise trainee info',
            data: yearVal
          }]
          this.searchThis = true
          this.loading = false
        }
        this.loadSearch = false
      })
      this.loading = false
    }
  }
}
</script>
