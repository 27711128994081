<template>
  <div>
    <div class="price_update">
      <h2 class="marquee_title">
        <b-container fluid style="color:white;font-weight: bold">{{ $t('globalTrans.daily_national_avg_price_retail') }}</b-container>
      </h2>
      <b-container fluid>
        <marquee
          behavior="scroll"
          direction="slide"
          onmouseover="this.stop ();"
          onmouseout="this.start ()"
          style="margin-top: 15px !important;"
        >
          <div class="d-inline" v-for="(item, index) in retailScroll" :key="index">
            <span style="font-weight:bold">{{ $i18n.locale === 'bn' ? item.commodity_name_bn : item.commodity_name }} ({{ ($i18n.locale==='bn') ? item.unit_name_bn : item.unit_name }} )</span>
            <span class="mx-3 font-weight-bold" :class="colorCheck(item)">
              {{ $n(item.a_r_lowestPrice) }} -
              {{ $n(item.a_r_howestPrice) }}
              <slot v-if="item.percentage === '0.00'"> </slot>
              <slot v-else>
                <slot v-if="item.percentage">
                  <slot v-if="item.percentage < 0">
                    <span style="color: var(--brand-secondary-color)"
                      >▼ {{ $n(item.percentage) }} %</span
                    >
                  </slot>
                  <slot v-else>
                    <span style="color: #ff0000"
                      >▲ {{ $n(item.percentage) }} %</span
                    >
                  </slot>
                </slot>
              </slot>
            </span>
          </div>
        </marquee>
      </b-container>
    </div>
    <div class="price_update mt-2">
      <h2 class="marquee_title">
        <b-container fluid  style="color:white;font-weight: bold">{{ $t('globalTrans.daily_national_avg_price_wholesale') }}</b-container>
      </h2>
      <b-container fluid>
        <marquee
          behavior="scroll"
          direction="slide"
          onmouseover="this.stop ();"
          onmouseout="this.start ()"
          style="margin-top: 15px !important;"
        >
          <div class="d-inline" v-for="(item, index) in wholeScroll" :key="index">
            <span style="font-weight:bold">{{ $i18n.locale === 'bn' ? item.commodity_name_bn : item.commodity_name }} ({{ ($i18n.locale==='bn') ? item.unit_name_bn_w : item.unit_name_w }} )</span>
            <span class="mx-3 font-weight-bold" :class="colorCheck(item)">
              {{ $n(item.a_w_lowestPrice) }} -
              {{ $n(item.a_w_howestPrice) }}
              <slot v-if="item.percentage_w === '0.00'"> </slot>
              <slot v-else>
                <slot v-if="item.percentage_w">
                  <slot v-if="item.percentage_w < 0">
                    <span style="color: var(--brand-secondary-color)"
                      >▼ {{ $n(item.percentage_w) }} %</span
                    >
                  </slot>
                  <slot v-else>
                    <span style="color: #ff0000"
                      >▲ {{ $n(item.percentage_w) }} %</span
                    >
                  </slot>
                </slot>
              </slot>
            </span>
          </div>
        </marquee>
      </b-container>
    </div>
    <div class="mt-3 text-center">
      <span class="mx-3 font-weight-bold text-danger" v-if="retailPriceDate">
        *** {{ $t('globalTrans.not_retail_price_date') }} : {{ retailPriceDate|dateFormat }} ***
      </span>
      <span class="mx-3 font-weight-bold text-danger" v-if="wholeSalePriceDate">
        *** {{ $t('globalTrans.not_whole_sale_price_date') }} : {{ wholeSalePriceDate|dateFormat }} ***
      </span>
    </div>
  </div>
</template>

<script>

export default {
  props: ['retailScroll', 'wholeScroll', 'retailPriceDate', 'wholeSalePriceDate'],
  created () {
  },
  data () {
    return {
      datas: [],
      marqueeAnimation: 'marquee 10s linear infinite',
      showMarquee: true
    }
  },
  methods: {
    colorCheck (item) {
      if (typeof item.percentage === 'undefined') {
        return ''
      }
      if (item.percentage === 0) {
        return ''
      }
      if (item.percentage < 0) {
        return 'text-success'
      }
      if (item.percentage > 0) {
        return 'text-danger'
      }
      return ''
    },
    colorCheckw (item) {
      if (typeof item.percentage_w === 'undefined') {
        return ''
      }
      if (item.percentage_w === '0') {
        return ''
      }
      if (item.percentage_w < 0) {
        return 'text-success'
      }
      if (item.percentage_w > 0) {
        return 'text-danger'
      }
      return ''
    },
    stopScroll () {
      this.showMarquee = false
    },
    startScroll () {
      this.showMarquee = true
    }
  }
}
</script>

<style lang="scss" scoped>
.price_update {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #d7fff2
}

.price_update .marquee_title {
    background-color: crimson;
    font-size: 15px;
    color: var(--white) !important;
    line-height: 25px;
    padding: 5px 4px;
    display: inline-block;
    margin: 0;
    min-width: 252px
}
.price_card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 15px;
    padding: 12px;
    min-height: 105px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    .title {
        h1 {
            font-weight: 700;
            font-size: 1rem
        }

        small {
            font-size: .7rem
        }
    }

    .price {
        margin-top: .8rem;
        p {
            font-size: .8em;
            font-weight: 700
        }
    }

    .img {
        width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 40px;
        border: 2px solid #fff;
        background-color: #fff;
        box-shadow: 0 10px 15px -3px rgba(0,0,0,.1);
        img {
            margin: 50% auto;
            transform: translateY(-50%)
        }
    }
}
</style>
