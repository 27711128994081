<template>
    <div>
        <b-overlay :show="loaderLine || loaderPie">
            <b-row v-if="Object.keys(data).length > 0">
                <b-col sm="3">
                    <div class="card_item_wrapper production_wrapper">
                        <div class="number_wrapper">
                            <p class="number">
                                <img src="../../../../../assets/images/ministry-dashboard/production.svg" class="item_icon img-fluid">
                            </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('ministryDashboard.production') }}</p>
                            <h2 class="count production_count">{{ $n(proudctionMetrict(data.production.productionQnty)) }}</h2>
                        </div>
                    </div>
                </b-col>
                <b-col sm="3">
                    <div class="card_item_wrapper allocation_wrapper">
                        <div class="number_wrapper">
                            <p class="number">
                                <img src="../../../../../assets/images/ministry-dashboard/allocation.svg" class="item_icon img-fluid">
                            </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('ministryDashboard.allocation') }} </p>
                            <h2 class="count allocation_count">{{$n(allocationMetric(data.allocation.allocationQnty))}}</h2>
                        </div>
                    </div>
                </b-col>
                <b-col sm="3">
                    <div class="card_item_wrapper sale_wrapper">
                        <div class="number_wrapper">
                        <p class="number">
                            <img src="../../../../../assets/images/ministry-dashboard/sale.svg" class="item_icon img-fluid">
                        </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('ministryDashboard.sale') }}</p>
                            <h2 class="count sale_count">{{$n(salesMetric(data.sale.saleQnty))}}</h2>
                        </div>
                    </div>
                </b-col>
                <b-col sm="3">
                    <div class="card_item_wrapper stock_wrapper">
                        <div class="number_wrapper">
                            <p class="number">
                                <img src="../../../../../assets/images/ministry-dashboard/stock.svg" class="item_icon img-fluid">
                            </p>
                        </div>
                        <div class="card_item">
                            <p class="title">{{ $t('ministryDashboard.stock') }}</p>
                            <h2 class="count stock_count">{{$n(stockMetric(data.stock))}} </h2>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="6">
                    <div class="grapChart_wrapper" style="height: 420px !important">
                        <h5 class="card_title">{{ $t('ministryDashboard.total_production_sale_status') }}</h5>
                        <apexchart  type="line" width="480" v-if="prodCheck" :options="lineOption" :series="lineSeriesData"></apexchart>
                    </div>
                </b-col>
                <b-col sm="6">
                    <div class="grapChart_wrapper" style="height: 420px !important">
                        <h5 class="card_title">{{ $t('ministryDashboard.total_production_status') }}</h5>
                        <apexchart type="pie" width="380" v-if="searchThis" :options="pieOption" :series="series()"></apexchart>
                    </div>
                </b-col>
            </b-row>
            <div class="text-right">
                <router-link :to="{ name: 'authSignUp.dashboard', params: {dashboardUrl: '/seeds-fertilizer-service/seeds-seeds/product-stock-report' }}" class="details_btn seed_btn">{{ $t('ministryDashboard.detail_btn') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
            </div>
        </b-overlay>
    </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { seedMinistryDashboard, seedLineMinistryDashboard } from '../../api/routes'

export default {
     data () {
      return {
        loaderLine: true,
        loaderPie: true,
        searchThis: false,
        prodCheck: false,
        data: {},
        pieSeriesData: [],
        productionValues: [],
        saleValues: [],
        stockValues: [],
        lineSeriesData: [],
        firstLabel: ['Sale (M.Ton)', 'Stock (M.Ton)'],
        firstLabelBn: ['বিক্রয় (মে.টন)', 'স্টক (মে.টন)'],
        chartLabel: ['Test'],
        chartLabelBn: ['TestBn']
      }
     },
    computed: {
        monthList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.dashboardMonthList
        },
        currentLocale: function () {
            return this.$i18n.locale
        },
        pieOption () {
          return {
            chart: {
              width: 380,
              type: 'pie'
            },
            labels: this.$i18n.locale === 'bn' ? this.firstLabelBn : this.firstLabel,
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 380
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          }
        },
        lineOption () {
            return {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [5, 7, 5],
                curve: 'straight',
                dashArray: [0, 0, 0]
            },
            legend: {
                tooltipHoverFormatter: function (val, opts) {
                    return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                }
            },
            markers: {
                size: 0,
                hover: {
                sizeOffset: 6
                }
            },
            xaxis: {
                categories: this.monthList.map(item => {
                    return this.$i18n.locale === 'bn' ? item.text_bn : item.text_en
                })
            },
            grid: {
                borderColor: '#f1f1f1'
                }
            }
        }
    },
    watch: {
       currentLocale (newVal, oldVal) {
           if (newVal !== oldVal) {
               this.setLineSeriesData()
           }
       }
    },
    created () {
      this.loadData()
      this.lineDashboard()
    },
    mounted () {
      core.index()
    },
    methods: {
        series () {
            return this.pieSeriesData
        },
        loadData () {
            this.loaderPie = true
             RestApi.getData(seedFertilizerServiceBaseUrl, seedMinistryDashboard).then((response) => {
                if (response.success) {
                    this.searchThis = true
                    this.data = response.data
                    this.saleQnty = parseFloat(this.data.sale.saleQnty) / 100
                    this.stockQnty = parseFloat(this.data.stock) / 100
                    this.pieSeriesData.push(this.saleQnty)
                    this.pieSeriesData.push(this.stockQnty)
                } else {
                    this.data = {}
                }
                this.loaderPie = false
            })
        },
        lineDashboard () {
             this.loaderLine = true
             RestApi.getData(seedFertilizerServiceBaseUrl, seedLineMinistryDashboard).then((response) => {
                if (response.success) {
                    this.searchThis = true
                    this.lineData = this.getMonthData(response.data)
                } else {
                    this.lineData = {}
                }
                this.loaderLine = false
            })
        },
        getMonthData (data) {
            this.monthList.map(item => {
                const production = data.production.find(production => production.month === item.value)
                    if (typeof production !== 'undefined') {
                        this.productionValues.push(production.productionQnty)
                    } else {
                        this.productionValues.push(0)
                    }
                const sale = data.sale.find(sale => sale.month === item.value)
                    if (typeof sale !== 'undefined') {
                        this.saleValues.push(sale.saleQnty)
                    } else {
                        this.saleValues.push(0)
                    }
                const stock = data.stock.find(stock => stock.month === item.value)
                    if (typeof stock !== 'undefined') {
                        if (stock.stockQuantity > 0) {
                            this.stockValues.push(stock.stockQuantity)
                        } else {
                            this.stockValues.push(0)
                        }
                } else {
                    this.stockValues.push(0)
                }
            })
            this.setLineSeriesData()
            this.prodCheck = true
        },
        proudctionMetrict (totalAmount) {
            return (totalAmount / 1000).toFixed(2)
        },
        allocationMetric (totalAmount) {
            return (totalAmount / 1000).toFixed(2)
        },
        salesMetric (totalAmount) {
            return (totalAmount / 1000).toFixed(2)
        },
        stockMetric (totalAmount) {
            return (totalAmount / 1000).toFixed(2)
        },
        setLineSeriesData () {
            this.lineSeriesData = [
                {
                    name: this.$t('ministryDashboard.production'),
                    data: this.productionValues
                },
                {
                    name: this.$t('ministryDashboard.sale'),
                    data: this.saleValues
                },
                {
                    name: this.$t('ministryDashboard.stock'),
                    data: this.stockValues
                }
            ]
        }
    }
}
</script>
<style scoped>
    .hrclass {
        border-top: 1px solid rgb(96, 149, 19) !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
    .info h4 {
        font-size: 26px;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    .info > p {
        color: #717171;
        font-size: 16px;
        padding-top: 10px;
        text-align: justify;
    }
</style>
