import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const movementSummaryPdfDetails = async (reportTitle, base64Logo, vm) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })

    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }

    const pdfContent = [
      { image: 'data:image/png;base64,' + base64Logo, height: 70, width: 70, style: 'logo', alignment: 'center' },
      { text: vm.$t('ministryDashboard.moa'), style: 'org', alignment: 'center' },
      { text: vm.$t('ministryDashboard.moa_address'), style: 'address', alignment: 'center' },
      { text: reportTitle, style: 'hh', alignment: 'center' }
    ]

    vm.movements.forEach((movement, index) => {
      pdfContent.push({ text: '', style: 'space' })
      pdfContent.push({ text: '', style: 'space' })
      pdfContent.push({ text: i18n.locale === 'en' ? movement.org_name_en : movement.org_name_bn, style: 'header2', alignment: 'left' })
      pdfContent.push({ text: '', style: 'space' })

      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.date'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.location'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('authentication.tour_details'), style: 'th', alignment: 'center', bold: true }
        ]
      ]

      movement.details.forEach((item, index1) => {
        allRows.push([
          { text: vm.$n(item.serial), alignment: 'center', style: 'td' },
          { text: dateFormat(item.date), alignment: 'center', style: 'td' },
          { text: i18n.locale === 'en' ? item.user.name : item.user.name_bn, alignment: 'center', style: 'td' },
          { text: i18n.locale === 'en' ? item.designation_en : item.designation_bn, alignment: 'center', style: 'td' },
          { text: i18n.locale === 'en' ? item.location_en : item.location_bn, alignment: 'center', style: 'td' },
          { text: i18n.locale === 'en' ? item.tour_details_en : item.tour_details_bn, alignment: 'center', style: 'td' }
        ])
      })

      pdfContent.push({
          table: {
              headerRows: 1,
              widths: ['10%', '14%', '12%', '12%', '12%', '40%'],
              body: allRows
          }
      })
    })

    var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'portrait',
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 9 : 8,
          margin: [3, 3, 3, 3]
        },
        thh: {
          fontSize: (i18n.locale === 'bn') ? 12 : 10,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 9 : 8,
          margin: [3, 3, 3, 3]
        },
        tdCustom: {
          fontSize: (i18n.locale === 'bn') ? 9 : 8,
          padding: [0, 0, 0, 10]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 10 : 11,
          margin: [3, 3, 3, 3]
        },
        space: {
          margin: [5, 0, 0, 5]
        },
        header: {
          fontSize: 17,
          bold: true,
          margin: [0, 5, 0, 0]
        },
        address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
        },
        logo: {
          margin: [0, 0, 0, 10]
        },
        org: {
          margin: [0, 0, 0, 8]
        },
        hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
    movementSummaryPdfDetails
}
