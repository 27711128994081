<template>
  <section>
      <b-container fluid>
        <div class="today_market_price_filter my-3">
          <b-overlay :show="loading">
            <b-row v-if="showData">
              <b-col sm="12" v-if="givenDateReports.length > 0">
                <h2 style="font-size: 24px !important;padding-left: 15px;"><b>{{ $t('damReport.product_name_and_description') }} : {{ $i18n.locale === 'bn' ? commodityData.commodity_name_bn : commodityData.commodity_name }}</b></h2>
                <table class="table table-striped table-hover mt-3">
                  <!-- <tr>
                    <th style="width: 20%">{{ $t('damReport.product_name_and_description') }}</th>
                    <td style="width: 5%"></td>
                    <td style="width: 30%">{{ $i18n.locale === 'bn' ? givenDateReports[0].commodity_name_bn : givenDateReports[0].commodity_name }}</td>
                  </tr> -->
                  <tr>
                    <th style="width: 20%">{{ $t('damReport.measurement') }}</th>
                    <td style="width: 5%"></td>
                    <td style="width: 30%">{{ $i18n.locale === 'bn' ? commodityData.unit_name_bn : commodityData.unit_name }}</td>
                  </tr>
                    <tr>
                    <th>{{ $t('damReport.today_market_rate') }}</th>
                    <td></td>
                    <td>{{ $n(parseFloat(givenDateReports[0].r_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(givenDateReports[0].r_highestPrice).toFixed(2)) }}</td>
                  </tr>
                    <tr>
                    <th>{{ $t('damReport.previus_week_market_rate') }}</th>
                    <td></td>
                    <td>
                      <slot v-if="givenDateReports[0].weak_r_lowestPrice >0">
                        {{ $n(parseFloat(givenDateReports[0].weak_r_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(givenDateReports[0].weak_r_highestPrice).toFixed(2)) }}
                      </slot>
                    </td>
                  </tr>
                    <tr>
                    <th>{{ $t('damReport.percentage_decrease_increase') }}</th>
                    <td></td>
                    <td>
                      <slot v-if="parseFloat(givenDateReports[0].r_avgPrice) && parseFloat(givenDateReports[0].weak_r_avgPrice)">
                        {{$n(parseFloat(calculatePercentage(givenDateReports[0].r_avgPrice,givenDateReports[0].weak_r_avgPrice)).toFixed(2))}}
                      </slot>
                    </td>
                  </tr>
                    <tr>
                    <th>{{ $t('damReport.previus_month_market_rate') }}</th>
                    <td></td>
                    <td>
                      <slot v-if="parseFloat(givenDateReports[0].month_r_lowestPrice) && parseFloat(givenDateReports[0].month_r_highestPrice)">
                        {{ $n(parseFloat(givenDateReports[0].month_r_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(givenDateReports[0].month_r_highestPrice).toFixed(2)) }}
                      </slot>
                    </td>
                  </tr>
                    <tr>
                    <th>{{ $t('damReport.percentage_decrease_increase') }}</th>
                    <td></td>
                    <td>
                      <slot v-if="parseFloat(givenDateReports[0].r_avgPrice) && parseFloat(givenDateReports[0].month_r_avgPrice)">
                        {{$n(parseFloat(calculatePercentage(givenDateReports[0].r_avgPrice, givenDateReports[0].month_r_avgPrice)).toFixed(2))}}
                      </slot>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('damReport.previus_year_market_rate') }}</th>
                    <td></td>
                    <td>
                      <slot v-if="parseFloat(givenDateReports[0].year_r_lowestPrice) && parseFloat(givenDateReports[0].year_r_highestPrice)">
                        {{ $n(parseFloat(givenDateReports[0].year_r_lowestPrice).toFixed(2)) }} - {{ $n(parseFloat(givenDateReports[0].year_r_highestPrice).toFixed(2)) }}
                      </slot>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('damReport.percentage_decrease_increase') }}</th>
                    <td></td>
                    <td>
                      <slot v-if="parseFloat(givenDateReports[0].r_avgPrice) && parseFloat(givenDateReports[0].year_r_avgPrice)">
                        {{$n(parseFloat(calculatePercentage(givenDateReports[0].r_avgPrice,givenDateReports[0].year_r_avgPrice)).toFixed(2))}}
                      </slot>
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-overlay>
        </div>
      </b-container>
    </section>
</template>
<style>
  .modal-body {
    padding-top: 0px !important;
  }
</style>
<script>
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
// import RestApi from '@/config/api_config'

export default {
  props: ['retailPriceDate', 'commodityId', 'commodityData'],
  components: {
  },
  created () {
    this.search.price_date = this.retailPriceDate
  },
  mounted () {
    if (this.commodityId) {
      this.loadData()
    }
  },
  data () {
    return {
      loading: false,
      showData: false,
      datas: [],
      values_commodity: [],
      btnLabel: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
      marqueeAnimation: 'marquee 10s linear infinite',
      filters_commodity: [{
        nameAll: this.$t('globalTrans.select_all'),
        nameNotAll: this.$t('globalTrans.deselect_all'),
        func () {
          return true
        }
      }],
      search: {
        commodity_id: 0,
        price_date: '',
        price_type_id: 'Retail',
        commodity_group_name_en: '',
        commodity_group_name_bn: '',
        commodity_sub_group_name_en: '',
        commodity_sub_group_name_bn: '',
        commodity_name_bn: '',
        commodity_name_en: ''
      },
      commodityNameList: [],
      options: {
        multi: true,
        groups: false,
        labelName: 'text',
        labelValue: 'value',
        cssSelected: option => (option.selected ? { 'background-color': '#5764c6' } : '')
      },
      showMarquee: true,
      clickSubmit: false,
      subgroupList: [],
      commoditySubGroupList: [],
      givenDateReports: [],
      stickyHeader: true,
      currentDate: '',
      listItemofyerterday: '',
      lastWeeks: [],
      lastMonths: [],
      lastYears: [],
      firstTime: true
    }
  },
  computed: {
  },
  methods: {
    calculatePercentage (oldAvg, newAvg) {
      const diffAmt = parseFloat(oldAvg) - parseFloat(newAvg)
      const totalPercentage = (100 * diffAmt) / parseFloat(oldAvg)
      return totalPercentage
    },
    async loadData () {
      this.loading = true
      this.showData = true
      const commodityIds = []
      this.values_commodity.forEach(item => {
        commodityIds.push(parseInt(item.value))
      })
      const searchData = {
        commodity_id: this.commodityId,
        price_type_id: this.search.price_type_id,
        price_date: this.search.price_date
      }
      // const searchData = {
      //   commodity_id: this.commodityId,
      //   price_type_id: this.search.price_type_id,
      //   price_date: '2024-11-10'
      // }
      const result = await RestApi.postData(agriMarketingServiceBaseUrl, '/bazar-dor/comparative-commodity-report', searchData)
      if (result.success) {
        this.loading = false
        this.givenDateReports = []
        if (result.data) {
          this.getRelationalData(result.data)
        } else {
          this.givenDateReports = []
          this.firstTime = false
        }
        this.clickSubmit = false
      } else {
        this.loading = false
        this.givenDateReports = []
        this.firstTime = false
        this.clickSubmit = false
      }
    },
    getRelationalData (data) {
      this.givenDateReports = data.givenDateReports
      this.lastWeeks = data.lastWeeks
      this.lastMonths = data.lastMonths
      this.lastYears = data.lastYears
      const list = this.givenDateReports.map(item => {
        const lastWeeks = this.lastWeeks.find(com => com.commodity_id === item.commodity_id)
        const commodityWeakData = {
          commodity_name: '',
          commodity_name_bn: '',
          weak_r_highestPrice: typeof lastWeeks !== 'undefined' ? lastWeeks.r_highestPrice : '',
          weak_r_lowestPrice: typeof lastWeeks !== 'undefined' ? lastWeeks.r_lowestPrice : '',
          weak_w_highestPrice: typeof lastWeeks !== 'undefined' ? lastWeeks.w_highestPrice : '',
          weak_w_lowestPrice: typeof lastWeeks !== 'undefined' ? lastWeeks.w_lowestPrice : '',
          weak_r_avgPrice: typeof lastWeeks !== 'undefined' ? lastWeeks.r_avgPrice : '',
          weak_w_avgPrice: typeof lastWeeks !== 'undefined' ? lastWeeks.w_avgPrice : ''
        }
        const lastMonths = this.lastMonths.find(com => com.commodity_id === item.commodity_id)
        const commodityMonthData = {
          month_r_highestPrice: typeof lastMonths !== 'undefined' ? lastMonths.r_highestPrice : '',
          month_r_lowestPrice: typeof lastMonths !== 'undefined' ? lastMonths.r_lowestPrice : '',
          month_w_highestPrice: typeof lastMonths !== 'undefined' ? lastMonths.w_highestPrice : '',
          month_w_lowestPrice: typeof lastMonths !== 'undefined' ? lastMonths.w_lowestPrice : '',
          month_r_avgPrice: typeof lastMonths !== 'undefined' ? lastMonths.r_avgPrice : '',
          month_w_avgPrice: typeof lastMonths !== 'undefined' ? lastMonths.w_avgPrice : ''
        }
        const lastYears = this.lastYears.find(com => com.commodity_id === item.commodity_id)
        const commodityYearData = {
          year_r_highestPrice: typeof lastYears !== 'undefined' ? lastYears.r_highestPrice : '',
          year_r_lowestPrice: typeof lastYears !== 'undefined' ? lastYears.r_lowestPrice : '',
          year_w_highestPrice: typeof lastYears !== 'undefined' ? lastYears.w_highestPrice : '',
          year_w_lowestPrice: typeof lastYears !== 'undefined' ? lastYears.w_lowestPrice : '',
          year_r_avgPrice: typeof lastYears !== 'undefined' ? lastYears.r_avgPrice : '',
          year_w_avgPrice: typeof lastYears !== 'undefined' ? lastYears.w_avgPrice : ''
        }
        return Object.assign({}, item, commodityWeakData, commodityMonthData, commodityYearData)
      })
      this.givenDateReports = list
    }
  }
}
</script>

<style lang="scss" scoped>
.price_update {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #d7fff2;
  .marquee_title {
    background-color: crimson;
    font-size: 15px;
    color: var(--white);
    line-height: 25px;
    padding: 5px 4px;
    display: inline-block;
    margin: 0;
    min-width: 252px;
  }
}
</style>
