<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details">
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table-simple striped small bordered>
                            <b-tr>
                                <b-th>{{ $t('globalTrans.date') }}</b-th>
                                <b-td>{{ movement.date | dateFormat }}</b-td>
                                <b-th>{{ $t('globalTrans.attachment') }}</b-th>
                                <b-td>
                                    <a v-if="movement.attachment" :href="authServiceBaseUrl + 'download-attachment?file=' + movement.attachment">
                                        <i class="ri-download-cloud-line"></i>
                                    </a>
                                    <a v-else>N/A</a>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('globalTrans.name') }}</b-th>
                                <b-td>{{ currentLocale === 'bn' ? movement.user.name_bn : movement.user.name }}</b-td>
                                <b-th>{{ $t('globalTrans.designation') }}</b-th>
                                <b-td>{{ currentLocale === 'bn' ? movement.designation_bn : movement.designation_en }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('globalTrans.location') }}</b-th>
                                <b-td colspan="3">{{ currentLocale === 'bn' ? movement.location_bn : movement.location_en }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th>{{ $t('authentication.tour_details') }}</b-th>
                                <b-td colspan="3">{{ currentLocale === 'bn' ? movement.tour_details_bn : movement.tour_details_en }}</b-td>
                            </b-tr>
                          </b-table-simple>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { authServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    this.movement = this.item
  },
  data () {
    return {
      movement: null,
      authServiceBaseUrl: authServiceBaseUrl,
      slOffset: 1
    }
  },
  computed: {
    currentLocale: function () {
      return this.$i18n.locale
    }
  }
}
</script>
