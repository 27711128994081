<template>
  <div>
    <div class="ministry_service">
      <b-tabs content-class="mt-3">
        <b-tab :title="$t('globalTrans.all')" active @click="tabChange(1)"></b-tab>
        <b-tab :title="$t('globalTrans.badc')"  @click="tabChange(2)"></b-tab>
        <b-tab :title="$t('globalTrans.bcic')"  @click="tabChange(3)"></b-tab>
      </b-tabs>
    </div>
    <iq-card>
      <template v-slot:body>
        <div class="org_filter_wrapper search-fertilizer ministry_service_radio">
          <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
            <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset"  enctype="multipart/form-data">
              <b-row>
                <!-- <b-col sm="3">
                  <b-form-group>
                    <b-form-radio-group
                      id="btn-radios-1"
                      v-model="search.fertilizer_type"
                      :options="fertilizerTypeOptions"
                      name="radios-btn-default"
                      buttons
                    ></b-form-radio-group>
                  </b-form-group>
                </b-col> -->
                <b-col sm="3" v-if="search.fertilizer_type === 3">
                  <b-form-group>
                    <b-form-radio-group
                      id="btn-radios-1"
                      v-model="search.type"
                      :options="uriaType"
                      name="radios-btn-default"
                      buttons
                    ></b-form-radio-group>
                  </b-form-group>
                </b-col>
                <b-col sm="4">
                  <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                      >
                      <template v-slot:label>
                      {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                      plain
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      id="fiscal_year_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                      <b-form-select-option :value="0" >{{ $t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="3" sm="12">
                  <ValidationProvider name="Month Name" vid="month_id">
                      <b-form-group
                          class="row"
                          label-cols-sm="5"
                          label-for="month_id"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                          {{$t('globalTrans.month')}}
                          </template>
                          <b-form-select
                          plain
                          v-model="search.month_id"
                          :options="monthListfiscalYear"
                          id="month_id"
                          :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                          {{ errors[0] }}
                          </div>
                      </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col>
                    <div class="">
                      <b-button class="float-left" type="submit" variant="primary">{{ $t('globalTrans.search')}}</b-button>
                    </div>
                </b-col>
              </b-row>
            </b-form>
          </ValidationObserver>
        </div>
      </template>
    </iq-card>
    <br/>
    <FertilizerPanel ref="child" :search="search" :currentFiscalYearId="currentFiscalYearId"/>
  </div>
</template>
<style>
.ministry_service_radio .btn-secondary {
  background-color: #fff !important;
  border-color: var(--iq-secondary);
  color: var(--color-777) !important;
}
.ministry_service .nav-tabs .nav-link.active {
  position: relative !important;
  color: var(--iq-white) !important;
  background: var(--brand-secondary-color) !important;
  border-radius: 0 !important;
}
.ministry_service .nav-tabs .nav-link {
  position: relative;
  padding: 0.6rem 1rem;
  color: var(--color-777) !important;
  font-weight: 600;
  font-size: 18px !important;
}
.ministry_service .nav-tabs {
    overflow: hidden;
    margin-bottom: 0;
    border: none;
    border-top: 1px solid var(--color-light-ccc-gray) !important;
    border-bottom: 1px solid var(--color-light-ccc-gray) !important;
    border-radius: 4px;
}
.ministry_service .nav-tabs .nav-link:hover {
    color: var(--iq-white) !important;
    background: var(--brand-secondary-color) !important;
    border-radius: 0;
}
#btn-radios-1 span {
  font-size: 18px;
}
.ministry_service_radio .btn-secondary:not(:disabled):not(.disabled).active, [dir=ltr][mode=light] .btn-secondary:not(:disabled):not(.disabled):active, [dir=ltr][mode=light] .show > .btn-secondary.dropdown-toggle {
  color: #fff !important;
  background-color: #00BCD5 !important;
  border-color: #00BCD5 !important;
}
</style>
<script>
import FertilizerPanel from './fertilizer/FertilizerPanel'

export default {
  name: 'UiDataTable',
  props: ['fiscalYearList', 'currentFiscalYearId'],
  components: {
    FertilizerPanel
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        fertilizer_type: 1,
        month_id: 0,
        type: 1
      }
    }
  },
  watch: {
    currentFiscalYearId: function (newVal, oldVal) {
      this.search.fiscal_year_id = newVal
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
      }
    }
  },
  async created () {
  },
  computed: {
    monthListfiscalYear: function () {
      const monthList = [
        {
          value: 7,
          text_en: 'July',
          text_bn: 'জুলাই'
        },
        {
          value: 8,
          text_en: 'August',
          text_bn: 'আগস্ট'
        },
        {
          value: 9,
          text_en: 'September',
          text_bn: 'সেপ্টেম্বর'
        },
        {
          value: 10,
          text_en: 'October',
          text_bn: 'অক্টোবর'
        },
        {
          value: 11,
          text_en: 'November',
          text_bn: 'নভেম্বর'
        },
        {
          value: 12,
          text_en: 'December',
          text_bn: 'ডিসেম্বর'
        },
        {
          value: 1,
          text_en: 'January',
          text_bn: 'জানুয়ারী'
        },
        {
          value: 2,
          text_en: 'February',
          text_bn: 'ফেব্রুয়ারী'
        },
        {
          value: 3,
          text_en: 'March',
          text_bn: 'মার্চ'
        },
        {
          value: 4,
          text_en: 'April',
          text_bn: 'এপ্রিল'
        },
        {
          value: 5,
          text_en: 'May',
          text_bn: 'মে'
        },
        {
          value: 6,
          text_en: 'June',
          text_bn: 'জুন'
        }
      ]
      return monthList.map(item => {
        const tmp = this.$i18n.locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
        return Object.assign({}, item, tmp)
      })
    },
    fertilizerTypeOptions () {
      return [
        { text: this.$t('globalTrans.all'), value: 1 },
        { text: this.$t('globalTrans.badc'), value: 2 },
        { text: this.$t('globalTrans.bcic'), value: 3 }
      ]
    },
    uriaType () {
      return [
        // { value: 0, text: this.$t('globalTrans.all') },
        { value: 1, text: this.$t('bcic.non_uria') },
        { value: 2, text: this.$t('bcic.uria') }
      ]
    }
  },
  methods: {
    tabChange (type) {
      this.search.fertilizer_type = type
    },
    searchData () {
      this.$refs.child.loadData()
    },
    getCommonFormat (data) {
        return data.map(item => {
            const tmp = this.currentLocale === 'en' ? { text: item.text_en } : { text: item.text_bn }
            return Object.assign({}, item, tmp)
        })
    }
  }
}
</script>
