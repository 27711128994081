<template>
  <div>
    <b-overlay :show="loadChart">
      <b-row class="justify-content-center">
        <b-col sm="3">
          <div class="license_card_item_wrapper sale_wrapper">
            <div class="item_1">
              <img src="../../../../../assets/images/ministry-dashboard/total-license-b.svg" class="item_icon img-fluid">
              <h2 class="count sale_count">{{ $n(chart.total_service) }}</h2>
            </div>
            <div class="item_2 pending">
              <p class="title">{{ $t('ministryDashboard.total_lrcpn') }}</p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <div class="license_card_item_wrapper production_wrapper">
            <div class="item_1">
              <img src="../../../../../assets/images/ministry-dashboard/total-application-o.svg" class="item_icon img-fluid">
              <h2 class="count production_count">{{ $n(chart.total_application) }}</h2>
            </div>
            <div class="item_2 total">
              <p class="title">{{ $t('ministryDashboard.total_appllication') }}</p>
            </div>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="license_card_item_wrapper allocation_wrapper">
            <div class="item_1">
              <img src="../../../../../assets/images/ministry-dashboard/total-application.svg" class="item_icon img-fluid">
              <h2 class="count production_count">{{ $n(chart.pending_application) }}</h2>
            </div>
            <div class="item_2 approved">
              <p class="title">{{ $t('ministryDashboard.pending_application') }}</p>
            </div>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="license_card_item_wrapper sale_wrapper">
            <div class="item_1">
              <img src="../../../../../assets/images/ministry-dashboard/total-application-b.svg" class="item_icon img-fluid">
              <h2 class="count production_count">{{ $n(chart.approved_application) }}</h2>
            </div>
            <div class="item_2 pending">
              <p class="title">{{ $t('ministryDashboard.approved_application') }}</p>
            </div>
          </div>
        </b-col>
        <b-col sm="3">
          <div class="license_card_item_wrapper stock_wrapper">
            <div class="item_1">
              <img src="../../../../../assets/images/ministry-dashboard/painding-application.svg" class="item_icon img-fluid">
              <h2 class="count production_count">{{ $n(chart.rejected_application) }}</h2>
            </div>
            <div class="item_2 process">
              <p class="title">{{ $t('ministryDashboard.rejected_application') }}</p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="justify-content-center border-top pt-3">
        <b-col sm="8">

          <div class="license-serive-list">
            <div class="license-list-title">
              <h5>{{ $t('org_pro.service_namel') }}</h5>
            </div>
            <div class="px-4">
                <div :class="serviceActive.id === data.id ? 'active' : ''" class="license-list-item" v-for="(data, index) in visibleItems" :key="index" @click="serviceActive = data">
                  <a>{{ $i18n.locale === 'bn' ? data.service_name_bn : data.service_name }}</a>
                </div>
            </div>
            <b-col cols="12" v-if="visibleItems.length">
              <div class="text-right">
                <button @click="toggleShowMore" class="details_btn inc_btn">
                  {{ itemsToShow === 7 ? $t('globalTrans.show_more') : $t('globalTrans.hide') }} <i class="fas fa-long-arrow-alt-right"></i>
                </button>
              </div>
            </b-col>
          </div>

          <!-- <table class="table table-border table-hover table-now-it tg" style="width: 100% !important;">
            <thead style="background: rgb(0, 187, 212); color: rgb(255, 255, 255);">
              <tr>
                <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                <th scope="col" class="tg-0pky text-center">{{ $t('org_pro.service_namel') }}</th>
                <th scope="col" class="tg-0pky text-center">{{ $t('globalTrans.count') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr :class="serviceActive.id === data.id ? 'table-active' : ''" v-for="(data, index) in visibleItems" :key="index" @click="serviceActive = data">
                <b-td class="text-center">{{  $n(index + 1) }}</b-td>
                <td scope="col" class="tg-0pky text-center text-decoration-none"><a class="text-dark" style="cursor:pointer">{{ $i18n.locale === 'bn' ? data.service_name_bn : data.service_name }}</a></td>
                <td scope="col" class="tg-0pky text-center">{{ $n(data.total) }}</td>
              </tr>
            </tbody>
          </table> -->

        </b-col>
        <div class="col-sm-4">
          <b-row class="licence-card-serice-wise" v-if="chartServices">
            <b-col cols="12"><h5 class="single-report-title text-center text-white w-100 mb-3">{{ $i18n.locale === 'bn' ? serviceActive.service_name_bn : serviceActive.service_name }}</h5></b-col>
            <b-col cols="12">
              <div class="card_item_wrapper total warehouse_card_item">
                <div class="number_wrapper">
                  <p class="number">
                    <img src="../../../../../assets/images/ministry-dashboard/total-application-w.svg" class="item_icon img-fluid">
                  </p>
                </div>
                <div class="card_item">
                  <h2 class="count">{{ $n(serviceActive.total) }}</h2>
                  <p class="title">{{ $t('ministryDashboard.total_appllication') }}</p>
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="card_item_wrapper pending warehouse_card_item">
                <div class="number_wrapper">
                  <p class="number">
                    <img src="../../../../../assets/images/ministry-dashboard/total-application-w.svg" class="item_icon img-fluid">
                  </p>
                </div>
                <div class="card_item">
                  <h2 class="count">{{ $n(serviceActive.pending) }}</h2>
                  <p class="title">{{ $t('ministryDashboard.pending_application') }}</p>
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="card_item_wrapper approved warehouse_card_item">
                <div class="number_wrapper">
                  <p class="number">
                    <img src="../../../../../assets/images/ministry-dashboard/total-application-w.svg" class="item_icon img-fluid">
                  </p>
                </div>
                <div class="card_item">
                  <h2 class="count">{{ $n(serviceActive.approved) }}</h2>
                  <p class="title">{{ $t('ministryDashboard.approved_application') }}</p>
                </div>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="card_item_wrapper rejected warehouse_card_item">
                <div class="number_wrapper">
                  <p class="number">
                    <img src="../../../../../assets/images/ministry-dashboard/total-application-w.svg" class="item_icon img-fluid">
                  </p>
                </div>
                <div class="card_item">
                  <h2 class="count">{{ $n(serviceActive.reject) }}</h2>
                  <p class="title">{{ $t('ministryDashboard.rejected_application') }}</p>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-row>
      <div class="text-right"><router-link :to="{ name: 'authSignUp.dashboard', params: { dashboardUrl: '/license-registration-service/license-step/reports/808' }}" class="details_btn inc_btn">{{ $t('ministryDashboard.detail_btn') }} <i class="fas fa-long-arrow-alt-right"></i></router-link>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { lisenceDashboardData } from '../../api/routes'

export default {
    data () {
      return {
        loadChart: false,
        chartServices: [],
        serviceActive: '',
        chart: {
          total_service: 0,
          total_application: 0,
          pending_application: 0,
          approved_application: 0,
          rejected_application: 0
        },
        series: [{
          data: []
        }],
        itemsToShow: 7,
        chartOptions: {
          chart: {
            type: 'bar'
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              dataLabels: {
                position: 'top'
              }
            }
          },
          dataLabels: {
            enabled: true,
            offsetX: -6
          },
          xaxis: {
            categories: []
          }
        }
      }
    },
    computed: {
      visibleItems () {
        return this.chartServices.slice(0, this.itemsToShow)
      },
      showMoreButton () {
        return this.itemsToShow < this.chartServices.length
      },
      local: function () {
          return this.$i18n.locale
      }
    },
    watch: {
      local: function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.getLanguateWiseData()
          }
      }
    },
    created () {
      this.loadData()
    },
    methods: {
        toggleShowMore () {
          this.itemsToShow = this.itemsToShow === 7 ? this.chartServices.length : 7
        },
        async loadData () {
          this.loadChart = true
          const response = await RestApi.getData(licenseRegistrationServiceBaseUrl, lisenceDashboardData)
          this.loadChart = false
          if (response.success) {
              this.chart = response.data
              this.chartServices = response.services
              this.serviceActive = response.services[0]
              response.services.forEach(item => {
                this.series[0].data.push(item.total)
                const serName = this.local === 'bn' ? item.service_name_bn : item.service_name
                this.chartOptions.xaxis.categories.push(serName)
              })
          } else {
              this.chart = {}
          }
        },
        async getLanguateWiseData () {
          this.loadChart = true
          this.series[0].data = []
          this.chartOptions.xaxis.categories = []
          await this.chartServices.forEach(item => {
            this.series[0].data.push(item.total)
            const serName = this.$i18n.locale === 'bn' ? item.service_name_bn : item.service_name
            this.chartOptions.xaxis.categories.push(serName)
          })
          this.loadChart = false
        },
        formatText (data, min) {
          if (data.length > min) {
            return data.substring(0, min) + '...'
          }
          return data
        }
    }
}
</script>
<style scoped>

  .single-report-title{
    padding-top: 6px;
    padding-bottom: 4px;
    color: #fff;
    background-color: darkolivegreen;
    border-radius: 5px;
    position: relative;
  }
  .single-report-title::after{
    content: "";
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-top: 12px solid #556b2f;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;

  }
  .licence-card-serice-wise .card_item_wrapper{
    margin-bottom: 16px!important;
  }
  .licence-card-serice-wise .total{
    background: linear-gradient(90deg, #f1ffd9, #ffffff)!important;
    border: 1px solid #668f1f!important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px!important;
  }
  .licence-card-serice-wise .total .number_wrapper{
    background-color: #668f1f!important;
  }
  .licence-card-serice-wise .pending{
    background: linear-gradient(90deg, #fff8db, #ffffff)!important;
    border: 1px solid #a0850d!important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px!important;
  }
  .licence-card-serice-wise .pending .number_wrapper{
    background-color: #a0850d!important;
  }
  .licence-card-serice-wise .approved{
    background: linear-gradient(90deg, #d7fff3, #ffffff)!important;
    border: 1px solid #1cb385!important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px!important;
  }
  .licence-card-serice-wise .approved .number_wrapper{
    background-color: #1cb385!important;
  }
  .licence-card-serice-wise .rejected{
    background: linear-gradient(90deg, #ffd6d6, #ffffff)!important;
    border: 1px solid #851111!important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px!important;
  }
  .licence-card-serice-wise .rejected .number_wrapper{
    background-color: #851111!important;
  }

  .license-serive-list {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
  }

  .license-list-title {
    padding: 4.5px;
    text-align: center;
    border-radius: 5px;
    background-color: #668f1f;
}
.license-list-title h5{
  color: #fff;
  font-weight: bold;
}
.license-list-item {
  text-align: center;
    border: 5px;
    margin: 15.5px 0;
    border: 2px solid transparent;
    border-left: 4px solid #668f1f;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition:all .2s linear;
}
.license-list-item a{
  cursor: pointer;
  padding: 6px;
  display: block;
  transition:all .2s linear;
}
.license-list-item:hover{
  background-color: #e7fdc0;
  border-color:#668f1f;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.license-list-item:hover a{
  color: #000!important;
}
.license-list-item.active{
  background-color: #e7fdc0;
  border-color:#668f1f;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.table-now-it th, .table-now-it td {
  padding: 3px !important;
}
.table-now-it:hover {
  cursor: pointer;
  cursor: hand;
}
</style>
<style type="text/css">
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{border-color:black;border-style:solid;border-width:1px;
  overflow:hidden;padding:10px 5px;word-break:normal;}
.tg th{border-color:black;border-style:solid;border-width:1px;
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;font-weight: bold;}
.tg .tg-0pky{border-color:inherit;text-align:left;vertical-align:top}
</style>
