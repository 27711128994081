<template>
    <b-row>
      <b-col sm="3">
        <div class="card_item_wrapper production_wrapper">
            <div class="number_wrapper">
              <p class="number">
                  <img src="../../../../../../assets/images/ministry-dashboard/import.svg" class="item_icon img-fluid">
              </p>
              </div>
              <div class="card_item">
              <p class="title">
                <slot v-if="(search.fertilizer_type === 2 || search.fertilizer_type === 1) || (search.fertilizer_type === 3 && search.type === 1)">
                  {{ $t('ministryDashboard.import') }}
                  <span>({{ $t('globalTrans.m_ton') }})</span>
                </slot>
                <slot v-else>
                  {{ $t('ministryDashboard.production') }}
                </slot>
              </p>
              <h2 class="count production_count">{{$n(importData)}}</h2>
            </div>
        </div>
      </b-col>
      <b-col sm="3">
        <div class="card_item_wrapper allocation_wrapper">
            <div class="number_wrapper">
            <p class="number">
                <img src="../../../../../../assets/images/ministry-dashboard/allocation.svg" class="item_icon img-fluid">
            </p>
            </div>
            <div class="card_item">
            <p class="title">{{ $t('ministryDashboard.allocation') }} </p>
            <h2 class="count allocation_count">{{$n(allocation)}}</h2>
            </div>
        </div>
      </b-col>
      <b-col sm="3">
        <div class="card_item_wrapper sale_wrapper">
            <div class="number_wrapper">
            <p class="number">
                <img src="../../../../../../assets/images/ministry-dashboard/distribution.svg" class="item_icon img-fluid">
            </p>
            </div>
            <div class="card_item">
            <p class="title">{{ $t('ministryDashboard.distribution') }} <span>({{ $t('globalTrans.m_ton') }})</span></p>
            <h2 class="count sale_count">{{$n(delivery)}}</h2>
            </div>
        </div>
      </b-col>
      <b-col sm="3">
        <div class="card_item_wrapper stock_wrapper">
            <div class="number_wrapper">
            <p class="number">
                <img src="../../../../../../assets/images/ministry-dashboard/stock.svg" class="item_icon img-fluid">
            </p>
            </div>
            <div class="card_item">
            <p class="title">{{ $t('ministryDashboard.stock') }} </p>
            <h2 class="count stock_count">{{$n(currentStock)}}</h2>
            </div>
        </div>
      </b-col>
    </b-row>
</template>
<style>
  .font-fertilizer {
    font-size: 1.6rem !important;
  }
</style>
<script>
export default {
  name: 'UiDataTable',
  props: ['importData', 'allocation', 'delivery', 'currentStock', 'search'],
  data () {
    return {}
  }
}
</script>
